import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { utils } from '@makeship/core';
import Image from 'next/image';
import { Caption, H5, P2 } from '../Typography';

const AccordionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.footerBorder};
`;

const AccordionSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.footerBorder};
`;

const AccordionHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  cursor: pointer;
`;

export const AccordionArrow = styled(Image)<{ $visible: boolean }>`
  ${({ $visible }) => $visible && `transform: rotate(180deg);`}
  transition: transform 0.25s ease-in-out;
`;

const AccordionBody = styled.div<{ $visible: boolean }>`
  max-height: ${({ $visible }) => ($visible ? '1000px' : '0')};
  height: auto;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
`;

const AccordionBodySection = styled.div`
  margin-bottom: 24px;
`;

export const AccordionBodyHeading = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

export const AccordionList = styled.ul`
  list-style-type: disc;
`;

export const AccordionListItem = styled.li`
  list-style-type: disc;
  margin-left: 30px;
`;

const AccordionSectionTitle = styled(H5)``;

const AccordionBodyText = styled(P2)``;

export type AccordionSectionType = {
  title: string;
  subtitle?: React.ReactNode;
  body: AccordionBodyType[];
};

type AccordionBodyType = {
  title?: string;
  bodyText?: string;
  body?: React.ReactNode;
};

type AccordionProps = {
  data: AccordionSectionType[];
  openSection?: number;
  resetOpenSection?: () => void;
  accordionLocation?: string;
};

const Accordion: React.FC<AccordionProps> = ({
  data,
  openSection,
  resetOpenSection,
  accordionLocation,
}: AccordionProps) => {
  const [active, setActive] = useState<number | undefined>();

  useEffect(() => {
    setActive(openSection);
  }, [openSection]);

  const handleClick = (index: number) => {
    if (active === index) {
      setActive(undefined);
      resetOpenSection && resetOpenSection();
    } else {
      setActive(index);
      accordionLocation &&
        posthog.capture('accordion_click', { eventLocation: accordionLocation, title: data[index].title });
    }
  };

  return (
    <AccordionWrapper>
      {data.map((section, index) => (
        <AccordionSection key={section.title}>
          <AccordionHead onClick={() => handleClick(index)}>
            <AccordionSectionTitle>{section.title}</AccordionSectionTitle>
            <AccordionArrow $visible={index === active} src="/assets/icons/dropdown-icon.svg" width={24} height={12} />
          </AccordionHead>
          <AccordionBody $visible={index === active}>
            {section.subtitle && section.subtitle}
            {section.body.map(
              (body) =>
                (body.body || body.bodyText) && (
                  <AccordionBodySection key={body.title || `Body Section ${index}`}>
                    {body.title && <AccordionBodyHeading>{body.title}</AccordionBodyHeading>}
                    {body.bodyText && <AccordionBodyText>{body.bodyText}</AccordionBodyText>}
                    {body.body}
                  </AccordionBodySection>
                ),
            )}
          </AccordionBody>
        </AccordionSection>
      ))}
    </AccordionWrapper>
  );
};

export default Accordion;
