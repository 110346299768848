import { gql } from '@apollo/client';
import { PRODUCT_DETAIL_FRAGMENT, PRODUCT_FRAGMENT_REDUCED } from '../fragments';

export const GET_PRODUCT_BY_HANDLE = gql`
  ${PRODUCT_DETAIL_FRAGMENT}
  query GetProductByHandle($handle: String!, $maxWidth: Int!) {
    productByHandle(handle: $handle) {
      ...ProductDetails
    }
  }
`;

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      ...ProductInfoReduced
    }
  }
`;

export const GET_PRODUCTS_BY_QUERY = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetProductsByQuery($first: Int!, $query: String!, $sortKey: ProductSortKeys, $reverse: Boolean, $images: Int) {
    products(first: $first, query: $query, sortKey: $sortKey, reverse: $reverse) {
      edges {
        node {
          ...ProductInfoReduced
        }
        cursor
      }
    }
  }
`;

export const GET_PRODUCTS_BY_QUERY_AFTER = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetProductsByQuery(
    $first: Int!
    $query: String!
    $after: String
    $sortKey: ProductSortKeys
    $reverse: Boolean
    $images: Int
  ) {
    products(first: $first, after: $after, query: $query, sortKey: $sortKey, reverse: $reverse) {
      edges {
        node {
          ...ProductInfoReduced
        }
        cursor
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  ${PRODUCT_DETAIL_FRAGMENT}
  query GetProductByID($id: ID, $maxWidth: Int!) {
    product(id: $id) {
      ...ProductDetails
    }
  }
`;
