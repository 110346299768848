import { Multipass } from 'multipass-js';
import * as Sentry from '@sentry/nextjs';

import Cookies from 'js-cookie';
import { checkout } from '../api/internal';
import { FlagBagType } from '../context/flagging';

export const getRoute = (url: string): string => new URL(url).pathname;

export const formatCheckoutUrl = (url: string): URL =>
  new URL(
    url.replace(
      process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_URL || '',
      process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_URL || '',
    ),
  );

export const getCheckoutUrl = async (webUrl: string, accessToken?: string, linkerParam?: string) => {
  const checkoutUrl = formatCheckoutUrl(webUrl);
  const params = !!linkerParam && linkerParam?.split('=');

  if (params && params.length > 1) {
    checkoutUrl.searchParams.append(params[0], params[1]);
  }

  const experimentFlagsCookie = Cookies.get('experimentFlags');

  if (experimentFlagsCookie) {
    const flagBag: FlagBagType = JSON.parse(experimentFlagsCookie) as FlagBagType;

    for (const [key, value] of Object.entries(flagBag.experimentFlags)) {
      checkoutUrl.searchParams.append(key, value);
    }
  }

  if (!accessToken) {
    return checkoutUrl;
  }

  return checkout(accessToken, checkoutUrl.toString())
    .then((res) => `${res.data.checkoutUrl}`)
    .catch(() => checkoutUrl);
};

export const createMultipassUrl = (url: string, email: string) => {
  try {
    const multipass = new Multipass(process.env.NEXT_PUBLIC_SHOPIFY_MULTIPASS_SECRET || '');
    const domain = (process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_URL || '').replace(/(^\w+:|^)\/\//, '');

    const customerData = {
      email,
    };

    return multipass.withCustomerData(customerData).withDomain(domain).withRedirect(url).url();
  } catch (err) {
    Sentry.captureException(err);
  }

  return url;
};
