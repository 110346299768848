import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../fragments';

const USER_ERRORS_FRAGMENT = gql`
  fragment UserErrorsInfo on UserError {
    field
    message
  }
`;

const CUSTOMER_USER_ERRORS_FRAGMENT = gql`
  fragment CustomerUserErrorsInfo on CustomerUserError {
    code
    field
    message
  }
`;

const CUSTOMER_ACCESS_TOKEN_FRAGMENT = gql`
  fragment CustomerAccessTokenInfo on CustomerAccessToken {
    accessToken
    expiresAt
  }
`;

export const CREATE_ACCESS_TOKEN = gql`
  ${CUSTOMER_ACCESS_TOKEN_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        ...CustomerAccessTokenInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const RENEW_ACCESS_TOKEN = gql`
  ${USER_ERRORS_FRAGMENT}
  mutation CustomerAccessTokenRenew($customerAccessToken: String!) {
    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
      customerAccessToken {
        ...CustomerAccessTokenInfo
      }
      userErrors {
        ...UserErrorsInfo
      }
    }
  }
`;

export const DELETE_ACCESS_TOKEN = gql`
  ${USER_ERRORS_FRAGMENT}
  mutation CustomerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      userErrors {
        ...UserErrorsInfo
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  query GetCustomer($customerAccessToken: String!, $cursor: String) {
    customer(customerAccessToken: $customerAccessToken) {
      ...CustomerInfo
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation CreateCustomer($input: CustomerCreateInput!, $cursor: String) {
    customerCreate(input: $input) {
      customer {
        ...CustomerInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const CUSTOMER_RECOVER = gql`
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const CUSTOMER_RESET = gql`
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_ACCESS_TOKEN_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerResetByUrl($resetUrl: URL!, $password: String!, $cursor: String) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        ...CustomerInfo
      }
      customerAccessToken {
        ...CustomerAccessTokenInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const CUSTOMER_ACTIVATE = gql`
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_ACCESS_TOKEN_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerActivateByUrl($activationUrl: URL!, $password: String!, $cursor: String) {
    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
      customer {
        ...CustomerInfo
      }
      customerAccessToken {
        ...CustomerAccessTokenInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_ACCESS_TOKEN_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!, $cursor: String) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        ...CustomerInfo
      }
      customerAccessToken {
        ...CustomerAccessTokenInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
      deletedCustomerAddressId
    }
  }
`;

export const UPDATE_DEFAULT_CUSTOMER_ADDRESS = gql`
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_USER_ERRORS_FRAGMENT}
  mutation customerDefaultAddressUpdate($customerAccessToken: String!, $id: ID!, $cursor: String) {
    customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $id) {
      customer {
        ...CustomerInfo
      }
      customerUserErrors {
        ...CustomerUserErrorsInfo
      }
    }
  }
`;
