import { scroller } from 'react-scroll';
import { utils } from '@makeship/core';
import { getCampaignStageTag, getStage } from '../../../utils/product';

export const scrollToPetitionProcess = () => {
  scroller.scrollTo('petition-process', {
    duration: 800,
    smooth: 'easeInOutQuad',
    offset: -150,
  });
};

export const scrollToVariants = () => {
  scroller.scrollTo('variants', {
    duration: 800,
    smooth: 'easeInOutQuad',
    offset: -300,
  });
};

export const hasPetitionLaunched = (launchDate: string | undefined): boolean => {
  if (!launchDate) return true;
  const launchDateObj = new Date(launchDate);
  const currentDate = new Date();
  return currentDate >= launchDateObj;
};

export const calculateLaunchDate = (petitionEndDate: string | undefined): string => {
  if (!petitionEndDate) return '';
  const myDate = new Date(petitionEndDate);
  myDate.setDate(myDate.getDate() + 85);
  return utils.getDate(myDate.toISOString());
};

export const renderLaunchDate = (paymentDate: string | undefined, petitionEndDate: string | undefined): string => {
  if (!paymentDate) return `${calculateLaunchDate(petitionEndDate)}`;
  return `${utils.getDate(paymentDate)}`;
};

export const createTags = (product: Shopify.Product, totalInventory: number, moq: number): string | undefined => {
  const stage = getStage(product.tags);
  // We will almost always return this
  if (product.id) {
    return `${product.title}, ${getCampaignStageTag(stage, totalInventory, moq)}, subscribed-product`;
  }
  // We need this to handle empty tags
  return undefined;
};

export const subscribeLabel = 'Enter your email to receive updates.';
