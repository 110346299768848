export const getFormattedAmount = (shopifyAmount: string | undefined) =>
  shopifyAmount ? parseFloat(shopifyAmount).toFixed(2) : '';

export const formatNumberStringWithComma = (numberString: string | number): string =>
  numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getRemainingAmountAfterPledge = (shopifyAmount: string | undefined) => {
  if (!shopifyAmount) return '';
  const remainingAmount = parseFloat(shopifyAmount) - 2;
  const remainingAmountStr = remainingAmount.toString();
  return getFormattedAmount(remainingAmountStr);
};
