import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { Caption, OverlineStyle2, P2Styles } from '../Typography';
import BorderInput from './borderInput';

const { Input } = BorderInput;

export type InputProps = {
  type: string;
  id: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  spellcheck?: string;
  error?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
  styles?: CSSProp;
};

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LabelsWrapper = styled.div`
  display: flex;
`;

const InputLabel = styled.label`
  ${P2Styles}
  display: inline-block;
  margin-bottom: 4px;
`;

const RequiredLabel = styled(OverlineStyle2)`
  display: flex;
  align-items: center;
  margin-left: auto;
  text-transform: capitalize;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

const InputField: React.FC<InputProps> = ({ label, id, value, error, errorMessage, required, ...rest }: InputProps) => (
  <InputContainer>
    <InputWrapper>
      <LabelsWrapper>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {required && <RequiredLabel>Required</RequiredLabel>}
      </LabelsWrapper>
      <Input {...rest} id={id} value={value} error={error} />
    </InputWrapper>
    <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
  </InputContainer>
);

export default InputField;
