import { ApolloQueryResult } from '@apollo/client';
import { ApolloClient } from '../clients';
import {
  GET_PRODUCTS_BY_QUERY,
  GET_PRODUCTS_BY_QUERY_AFTER,
  GET_PRODUCT_BY_HANDLE,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_RECOMMENDATIONS,
} from './queries';
import { filterNonDemoNonDraftProducts } from '../../utils/product';
import { logAndThrowSentryError } from '../../utils/errors';

export const getProductByHandle = (handle: string, maxWidth = 1000): Promise<Shopify.Product | null | undefined> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_PRODUCT_BY_HANDLE,
    variables: {
      handle,
      maxWidth,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getProductByHandle', error);
    return data?.productByHandle;
  });

export const getProductRecommendations = (productId: string): Promise<ApolloQueryResult<Shopify.QueryRoot>> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_PRODUCT_RECOMMENDATIONS,
    variables: {
      productId,
    },
  });

export const getProductsByQuery = (
  query: string,
  first = 25,
  after = '',
  sortKey = 'ID',
  reverse = false,
  images = 1,
): Promise<Shopify.ProductEdge[]> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: after ? GET_PRODUCTS_BY_QUERY_AFTER : GET_PRODUCTS_BY_QUERY,
    variables: {
      query,
      first,
      after,
      sortKey,
      reverse,
      images,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getProductsByQuery', error);
    return filterNonDemoNonDraftProducts(data?.products?.edges);
  });

export const getProductByID = (id: string, maxWidth = 1000): Promise<Shopify.Product | null | undefined> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_PRODUCT_BY_ID,
    variables: {
      id,
      maxWidth,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getProductByID', error);
    return data?.product;
  });
