import { AxiosResponse } from 'axios';
import { StorefrontClient } from '../../clients';

export const getProductSalesQuantityByExternalProductId = async (
  productId: string,
): Promise<AxiosResponse<ProductSalesQuantity>> =>
  StorefrontClient.get<ProductSalesQuantity>(`/products/${productId}/sales-quantity`).catch((err) =>
    Promise.reject(err.response.data.message),
  );

export const getSideBarItems = async (): Promise<AxiosResponse<SidebarItemsType[]>> =>
  StorefrontClient.get<SidebarItemsType[]>('/sidebar/items').catch((err) => Promise.reject(err.response.data.message));
