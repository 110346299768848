import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';

const SocialWrapper = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  margin-right: 8px;
`;

export type SocialType = {
  icon: string;
  alt: string;
  link: string;
};

export const defaultSocialLinks: SocialType[] = [
  {
    icon: '/assets/icons/twitter-icon.svg',
    alt: 'twitter icon',
    link: 'https://www.twitter.com/makeship',
  },
  {
    icon: '/assets/icons/facebook-icon.svg',
    alt: 'facebook icon',
    link: 'https://www.facebook.com/makeship',
  },
  {
    icon: '/assets/icons/instagram-icon.svg',
    alt: 'instagram icon',
    link: 'https://www.instagram.com/makeship',
  },
  {
    icon: '/assets/icons/tiktok-icon.svg',
    alt: 'tiktok icon',
    link: 'https://www.tiktok.com/@makeship',
  },
];

type SocialProps = {
  handle?: string;
  links?: SocialType[];
  socialLocation?: string;
};

const Social: React.FC<SocialProps> = ({ handle, links, socialLocation }: SocialProps) => {
  const socialLinks: SocialType[] = links || [
    {
      icon: '/assets/icons/twitter-icon.svg',
      alt: 'twitter icon',
      link: `https://twitter.com/intent/tweet?url=${process.env.NEXT_PUBLIC_DOMAIN}/products/${handle}&text=Checkout%20this%20@Makeship%20campaign!`,
    },
    {
      icon: '/assets/icons/facebook-icon.svg',
      alt: 'facebook icon',
      link: `https://www.facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_DOMAIN}/products/${handle}`,
    },
  ];

  return (
    <>
      <SocialWrapper>
        {socialLinks.map((social) => (
          <SocialLink
            key={social.link}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              socialLocation &&
              posthog.capture(`${socialLocation}_social_click`, {
                social_platform: social.alt.replace(' icon', '').trim(),
                link_href: social.link,
              })
            }
          >
            <Image src={social.icon} width={30} height={30} alt={social.alt} quality={90} />
          </SocialLink>
        ))}
      </SocialWrapper>
    </>
  );
};

export default Social;
