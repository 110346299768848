import config from '../../config.json';

export const getNumItems = (checkout: Shopify.Checkout | undefined): number =>
  checkout ? checkout.lineItems.edges.reduce((prev, curr) => prev + curr.node.quantity, 0) : 0;

export const getShippingRate = (checkout: Shopify.Checkout | undefined): number =>
  config.shipping.base + (getNumItems(checkout) - 1) * config.shipping.additional;

export const getPricePerQuantity = (price: string, quantity: number): string =>
  (parseFloat(price) * quantity).toFixed(2);

export const getQtyOfProductInCart = (checkout: Shopify.Checkout | undefined, productName: string): number => {
  if (!checkout) return 0;

  const matchingProductTitle =
    checkout?.lineItems && checkout?.lineItems.edges.find((item) => item.node.title === productName);
  if (matchingProductTitle) {
    return matchingProductTitle.node.quantity;
  }
  return 0;
};
