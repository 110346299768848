import React from 'react';
import ReactModal from 'react-modal';

type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  theme: ReactModal.Styles;
  children: React.ReactNode;
};

ReactModal.setAppElement('body');

const Modal: React.FC<ModalProps> = ({ isOpen, onRequestClose, theme, children }: ModalProps) => (
  <ReactModal isOpen={isOpen} style={theme} shouldCloseOnOverlayClick onRequestClose={onRequestClose}>
    {children}
  </ReactModal>
);

export default Modal;
