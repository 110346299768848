/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react';
import styled, { FlattenInterpolation, ThemeProps, css } from 'styled-components';
import Link from 'next/link';
import Flickity, { FlickityOptions } from 'react-flickity-component';

import posthog from 'posthog-js';
import { PromotionContent, BannerType } from '../../types/common';

import { S3Styles } from '../Typography';

interface FlickityExtended extends Flickity {
  slider?: HTMLElement;
}

const BannerWrapper = styled.div<{ backgroundColor: FlattenInterpolation<ThemeProps<any>> }>`
  width: 100%;
  ${({ backgroundColor }) => backgroundColor}
  height: ${({ theme }) => `${theme.bannerHeight}px`};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
  padding: 0 44px;
`;

const Title = styled.span<{ textColor: FlattenInterpolation<ThemeProps<any>> }>`
  ${S3Styles};
  ${({ textColor }) => textColor};
  margin-right: 4px;
`;

const StyledLink = styled.a<{ textColor: FlattenInterpolation<ThemeProps<any>> }>`
  ${S3Styles};
  ${({ textColor }) => textColor};
  text-decoration: underline;
  &:active {
    ${({ textColor }) => textColor};
  }
  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const StyledFlickity = styled(Flickity)`
  .flickity-prev-next-button {
    background-color: transparent;
  }
  .flickity-button-icon {
    left: 35%;
    top: 35%;
    width: 30%;
    height: 30%;
  }
`;

const CarouselWrapper = styled.div``;

const getTextColor = (bannerType: BannerType): FlattenInterpolation<ThemeProps<any>> =>
  new Map([
    [
      BannerType.Standard,
      css`
        ${({ theme }) => `color: ${theme.colors.primaryDarker};`}
      `,
    ],
    [
      BannerType.BlueOnGreen,
      css`
        ${({ theme }) => `color: ${theme.colors.primaryDark};`}
      `,
    ],
    [
      BannerType.LightBlue,
      css`
        ${({ theme }) => `color: ${theme.colors.primaryDark};`}
      `,
    ],
    [
      BannerType.DarkTextOnPink,
      css`
        ${({ theme }) => `color: ${theme.colors.darkTextOnPink};`}
      `,
    ],
  ]).get(bannerType) ||
  css`
    ${({ theme }) => `color: ${theme.colors.primaryDark};`}
  `;

const getBackgroundColor = (bannerType: BannerType): FlattenInterpolation<ThemeProps<any>> =>
  new Map([
    [
      BannerType.Standard,
      css`
        ${({ theme }) => `background-color: ${theme.colors.primaryLighter};`}
      `,
    ],
    [
      BannerType.BlueOnGreen,
      css`
        ${({ theme }) => `background-color: ${theme.colors.greenLight};`}
      `,
    ],
    [
      BannerType.LightBlue,
      css`
        ${({ theme }) => `background-color: ${theme.colors.blueLight2};`}
      `,
    ],
    [
      BannerType.DarkTextOnPink,
      css`
        ${({ theme }) => `background-color: ${theme.colors.lightPink};`}
      `,
    ],
  ]).get(bannerType) ||
  css`
    ${({ theme }) => `background-color: ${theme.colors.primaryLight}`}
  `;

type PromotionProps = {
  promotionContent: PromotionContent;
};

const StandardPromotion: React.FC<PromotionProps> = ({ promotionContent }: PromotionProps) => {
  const { text, link, linkText, subtitle, icon } = promotionContent;
  return (
    <>
      <BannerWrapper backgroundColor={getBackgroundColor(promotionContent.bannerType)}>
        <ContentWrapper>
          {icon && <Icon src={icon} alt={text} width={33} height={22} />}
          {text && <Title textColor={getTextColor(promotionContent.bannerType)}>{text}</Title>}
          <TextWrapper>
            {subtitle && <Title textColor={getTextColor(promotionContent.bannerType)}>{subtitle} </Title>}
            {link && linkText && (
              // Uncomment when banner link directs to a link
              <Link href={link} passHref>
                <StyledLink
                  textColor={getTextColor(promotionContent.bannerType)}
                  id="standard-global-banner"
                  onClick={() => posthog.capture(promotionContent.posthogTracking || 'global_banner_click')}
                >
                  {linkText}
                </StyledLink>
              </Link>
            )}
          </TextWrapper>
        </ContentWrapper>
      </BannerWrapper>
    </>
  );
};

type PromotionMap = {
  [K in BannerType]: JSX.Element;
};

const renderPromotion = (index: number, promotionContent: PromotionContent) => {
  const promotionMap: PromotionMap = {
    [BannerType.Standard]: <StandardPromotion key={index} promotionContent={promotionContent} />,
    [BannerType.BlueOnGreen]: <StandardPromotion key={index} promotionContent={promotionContent} />,
    [BannerType.LightBlue]: <StandardPromotion key={index} promotionContent={promotionContent} />,
    [BannerType.DarkTextOnPink]: <StandardPromotion key={index} promotionContent={promotionContent} />,
  };

  const { bannerType } = promotionContent;
  return promotionMap[bannerType] || null;
};

type BannerProps = {
  hidden: boolean;
  promotionContentArray: PromotionContent[];
};

const Banner: React.FC<BannerProps> = ({ hidden, promotionContentArray }: BannerProps) => {
  const flickityRef = useRef<FlickityExtended>();

  const flickityOptions: FlickityOptions = {
    groupCells: true,
    initialIndex: 0,
    draggable: promotionContentArray.length > 1,
    cellAlign: 'center',
    autoPlay: 5000,
    wrapAround: true,
  };

  const handleDragStart = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'none';
    });
  };

  const handleDragEnd = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'all';
    });
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('dragStart', handleDragStart);
      flickityRef.current.on('dragEnd', handleDragEnd);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('dragStart', handleDragStart);
        flickityRef.current.off('dragEnd', handleDragEnd);
      }
    };
  }, []);

  return hidden ? null : (
    <CarouselWrapper>
      <StyledFlickity
        flickityRef={(ref) => {
          flickityRef.current = ref;
        }}
        elementType="div"
        options={{ ...flickityOptions, prevNextButtons: promotionContentArray.length > 1 }}
        disableImagesLoaded={false}
      >
        {promotionContentArray.map((promotionContent, index) => renderPromotion(index, promotionContent))}
      </StyledFlickity>
    </CarouselWrapper>
  );
};
export default Banner;
