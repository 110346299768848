import styled, { css } from 'styled-components';

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
  wide = 'wide',
}

export type ButtonProps = {
  disabled?: boolean;
  size?: Size;
};

const modifiers = {
  [Size.small]: { padding: '7px 24px' },
  [Size.medium]: { padding: '12px 24px' },
  [Size.large]: { padding: '16px 24px' },
  [Size.wide]: { padding: '12px 100px' },
};

export const ButtonStyles = css<ButtonProps>`
  padding: ${({ size }) => size && modifiers[size].padding};
  font-family: ${({ theme }) => theme.fonts.button};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.buttonWeight};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonA = styled.a<ButtonProps>`
  ${ButtonStyles}
  display: inline-block;
`;

export const Button = styled.button<ButtonProps>`
  ${ButtonStyles}
`;

Button.defaultProps = {
  disabled: false,
  size: Size.medium,
};

ButtonA.defaultProps = {
  disabled: false,
  size: Size.medium,
};
