import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { Button } from '@makeship/core';
import posthog from 'posthog-js';
import { P2 } from '../Typography';

import config from '../../../config.json';
import CookieModal from '../Modal/CookieModal';
import { CookieConsent } from '../../types/cookies';
import { acceptAllCookies } from '../../utils/cookies';

const { routes } = config;

const CookieConsentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryDarker};
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 46px;
  z-index: 2;
  padding: 16px 20px;
  border-radius: 0px 30px 30px 0px;
  width: 95%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 816px;
    flex-direction: row;
  }
`;

const CookieConsentWrapperGDPR = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryDarker};
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  position: fixed;
  bottom: 46px;
  width: 95%;
  z-index: 2;
  padding: 16px 20px;
  border-radius: 0px 30px 30px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 1220px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Text = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.neutral1};
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledButton = styled(Button.Tertiary)`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 300px;
  }
`;

const CustomizeStyledButtonGDPR = styled(Button.Tertiary)`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 180px;
  }
`;

const StyledButtonGDPR = styled(Button.Secondary)`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 180px;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row-reverse;
    align-items: center;
    gap: 12px;
  }
`;

type CookieBarProps = {
  isInEU: boolean;
};

const CookieBar: React.FC<CookieBarProps> = ({ isInEU }: CookieBarProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookieConsent] = useState(Cookies.get('cookie_actioned') === 'true');
  const [isLoadingCookieConsent, setIsLoadingCookieConsent] = useState(true);
  const [isActioned, setIsActioned] = useState(cookieConsent);

  const handleCustomizeCTAClick = () => {
    setIsModalOpen(true);
    posthog.capture('cookie_consent_customize_clicked');
  };

  useEffect(() => {
    if (cookieConsent === undefined) {
      setIsLoadingCookieConsent(true);
    } else {
      setIsLoadingCookieConsent(false);
    }
  }, [cookieConsent]);

  if (!isLoadingCookieConsent && !isActioned) {
    return isInEU ? (
      <>
        {!isModalOpen && (
          <CookieConsentWrapperGDPR>
            <Text>
              🍪 We use cookies to enhance your experience!{' '}
              <Link href={routes.cookiesPolicy} passHref>
                <StyledA>Cookie Policy</StyledA>
              </Link>
            </Text>
            <CTAWrapper>
              <ButtonWrapper>
                <CustomizeStyledButtonGDPR onClick={handleCustomizeCTAClick}>Customize</CustomizeStyledButtonGDPR>
                <StyledButtonGDPR
                  data-testid="cookie-consent-button"
                  onClick={() => {
                    Cookies.set('cookie_actioned', 'true', { expires: 365 });
                    setIsActioned(true);
                    acceptAllCookies();
                    posthog.capture('cookie_consent_accepted', { location: 'cookie_bar' });
                  }}
                >
                  Accept All
                </StyledButtonGDPR>
              </ButtonWrapper>
              <StyledA
                onClick={() => {
                  Cookies.set('cookie_actioned', 'true', { expires: 365 });
                  setIsActioned(true);
                  Cookies.set(CookieConsent.ThirdParty, 'false', { expires: 365 });
                  Cookies.set(CookieConsent.Statistical, 'false', { expires: 365 });
                  Cookies.set(CookieConsent.Functional, 'false', { expires: 365 });
                  posthog.capture('cookie_consent_rejected', { location: 'cookie_bar' });
                }}
              >
                Reject Non-Mandatory Cookies
              </StyledA>
            </CTAWrapper>
          </CookieConsentWrapperGDPR>
        )}
        <CookieModal
          isOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false);
            if (Cookies.get('cookie_actioned')) {
              setIsActioned(true);
            }
          }}
        />
      </>
    ) : (
      <CookieConsentWrapper>
        <Text>
          🍪 We use cookies to enhance your experience!{' '}
          <Link href={routes.cookiesPolicy} passHref>
            <StyledA>Cookie Policy</StyledA>
          </Link>
        </Text>
        <StyledButton
          data-testid="cookie-consent-button"
          onClick={() => {
            Cookies.set('cookie_actioned', 'true', { expires: 365 });
            setIsActioned(true);
            posthog.capture('cookie_consent_accepted', { location: 'cookie_bar' });
          }}
        >
          Got It!
        </StyledButton>
      </CookieConsentWrapper>
    );
  }
  return null;
};

export default CookieBar;
