import Cookies from 'js-cookie';
import { addItem, removeItem, updateItem, getCheckout, updateCartWithDiscount, addItems } from '../api/checkout';
import { getCustomer } from '../api/customer';
import { accessTokenKey, usernameKey } from '../utils/cookies';

export enum ActionTypes {
  SHOW_CART = 'SHOW_CART',
  HIDE_CART = 'HIDE_CART',
  TOGGLE_CART = 'TOGGLE_CART',
  UPDATE_CHECKOUT = 'UPDATE_CHECKOUT',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
}

export type Action = {
  type: ActionTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export const showCart = (): Action => ({
  type: ActionTypes.SHOW_CART,
});

export const hideCart = (): Action => ({
  type: ActionTypes.HIDE_CART,
});

export const toggleCart = (): Action => ({
  type: ActionTypes.TOGGLE_CART,
});

export const updateCheckout = (checkout: Shopify.Checkout | undefined): Action => ({
  type: ActionTypes.UPDATE_CHECKOUT,
  payload: checkout,
});

export const updateUser = (
  accessToken: string,
  customer: Shopify.Customer | undefined | null,
  storefrontCustomer?: Customer,
): Action => ({
  type: ActionTypes.UPDATE_USER,
  payload: {
    accessToken,
    customer,
    storefrontCustomer,
  },
});

export const updateCustomer = (customer: Customer): Action => ({
  type: ActionTypes.UPDATE_CUSTOMER,
  payload: customer,
});

export const logoutUser = (): Action => ({
  type: ActionTypes.UPDATE_USER,
  payload: undefined,
});

export const addProduct = async (
  cart: Cart,
  product: Shopify.Product,
  number: number,
  variant?: Shopify.ProductVariant,
): Promise<Action> => {
  let { checkout } = cart;

  if (checkout && variant) {
    checkout = (await addItem(variant.id, checkout.id, number).then((data) => data?.checkout)) as Shopify.Checkout;
  }

  return updateCheckout(checkout);
};

export const addProducts = async (
  cart: Cart,
  products: Shopify.Product,
  number: number,
  variants: Shopify.ProductVariant[],
): Promise<Action> => {
  let { checkout } = cart;

  if (checkout && variants) {
    checkout = (await addItems(variants, checkout.id, number).then((data) => data?.checkout)) as Shopify.Checkout;
  }

  return updateCheckout(checkout);
};

export const removeProduct = async (lineItem: Shopify.CheckoutLineItem, cart: Cart): Promise<Action> => {
  let { checkout } = cart;
  if (checkout) {
    const { id } = checkout;
    checkout = (await removeItem([lineItem.id], id).then((data) => data?.checkout)) as Shopify.Checkout;
    // If removing item failed - returned null checkout, get checkout again
    if (!checkout) {
      checkout = await getCheckout(id);
    }
  }
  return updateCheckout(checkout);
};

export const clientRemoveProduct = (lineItem: Shopify.CheckoutLineItem, cart: Cart): Action => {
  const { checkout } = cart;
  if (checkout) {
    checkout.lineItems.edges = checkout.lineItems.edges.filter(({ node }) => node.id !== lineItem.id);
  }
  return updateCheckout(checkout);
};

export const quantityModifier = async (lineItem: Shopify.CheckoutLineItem, cart: Cart): Promise<Action> => {
  let { checkout } = cart;

  const updateLineItems: Shopify.CheckoutLineItemUpdateInput[] = [
    {
      id: lineItem.id,
      quantity: lineItem.quantity,
      variantId: lineItem.variant?.id,
    },
  ];

  if (checkout) {
    checkout = (await updateItem(updateLineItems, checkout.id).then((data) => data?.checkout)) as Shopify.Checkout;
  }

  return updateCheckout(checkout);
};

const clientQuantityModifier = (lineItem: Shopify.CheckoutLineItem, cart: Cart, modifier: number): Action => {
  const { checkout } = cart;
  if (checkout) {
    checkout.lineItems.edges = checkout.lineItems.edges.map((item) => {
      const newItem = item;
      if (newItem.node.id === lineItem.id) {
        newItem.node.quantity += modifier;
      }
      return newItem;
    });
    checkout.lineItems.edges = checkout.lineItems.edges.filter(({ node }) => node.quantity >= 0);
  }
  return updateCheckout(checkout);
};

export const clientIncreaseQuantity = (lineItem: Shopify.CheckoutLineItem, cart: Cart): Action =>
  clientQuantityModifier(lineItem, cart, 1);

export const clientDecreaseQuantity = (lineItem: Shopify.CheckoutLineItem, cart: Cart): Action =>
  clientQuantityModifier(lineItem, cart, -1);

export const login = (accessToken: string, expiry: string): Promise<Action> => {
  Cookies.set(accessTokenKey, accessToken, { expires: new Date(expiry), secure: true });
  return getCustomer(accessToken).then((customer) => updateUser(accessToken, customer));
};

export const logout = (): Action => {
  Cookies.remove(accessTokenKey);
  Cookies.remove(usernameKey);
  return logoutUser();
};

export const renewAccessToken = (accessToken: string, expiry: string, customer: Shopify.Customer): Action => {
  Cookies.set(accessTokenKey, accessToken, { expires: new Date(expiry), secure: true });
  return updateUser(accessToken, customer);
};

export const addCustomer = (customer: Customer): Action => {
  Cookies.set(usernameKey, customer.username || '', { expires: new Date('2099-01-01'), secure: true });
  return updateCustomer(customer);
};

export const updateCartWithDiscountCode = async (code: string, cart: Cart): Promise<Action> => {
  let { checkout } = cart;

  if (checkout) {
    const { id } = checkout;
    checkout = (await updateCartWithDiscount(code, id).then((data) => data?.checkout)) as Shopify.Checkout;
  }
  return updateCheckout(checkout);
};
