import { gql } from '@apollo/client';
import { CHECKOUT_FRAGMENT } from '../fragments';

export const GET_CHECKOUT = gql`
  ${CHECKOUT_FRAGMENT}
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutDetails
      }
    }
  }
`;

export const CREATE_CHECKOUT = gql`
  ${CHECKOUT_FRAGMENT}
  mutation CreateCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutDetails
      }
    }
  }
`;

export const ADD_ITEM = gql`
  ${CHECKOUT_FRAGMENT}
  mutation AddItem($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutDetails
      }
    }
  }
`;

export const REMOVE_ITEM = gql`
  ${CHECKOUT_FRAGMENT}
  mutation RemoveItem($lineItemIds: [ID!]!, $checkoutId: ID!) {
    checkoutLineItemsRemove(lineItemIds: $lineItemIds, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutDetails
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  ${CHECKOUT_FRAGMENT}
  mutation UpdateItem($lineItems: [CheckoutLineItemUpdateInput!]!, $checkoutId: ID!) {
    checkoutLineItemsUpdate(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutDetails
      }
    }
  }
`;

export const UPDATE_CART_APPLY_DISCOUNT = gql`
  mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
