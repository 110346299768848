import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { P2Styles, Caption } from '../Typography';

import BorderInput from './borderInput';

const { Input } = BorderInput;

export type PasswordFieldProps = {
  label: string;
  id: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showPasswordIcon?: boolean;
  required?: boolean;
  spellcheck?: string;
  error?: boolean;
  errorMessage?: string;
};

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px;
`;

const InputWrapper = styled.div<{ placeholder: string; isFocused: boolean }>`
  width: 100%;
  &::after {
    content: '${({ placeholder }) => placeholder && placeholder}';
    visibility: ${({ isFocused }) => (isFocused ? 'hidden' : 'visible')};
    color: black;
    position: absolute;
    top: 46px;
    left: 12px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 8px;
    opacity: 15%;
  }
`;

const InputLabel = styled.label`
  ${P2Styles}
  display: inline-block;
  margin-bottom: 4px;
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 7px;
  left: 95%;
  cursor: pointer;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  id,
  value,
  error,
  errorMessage,
  showPasswordIcon = false,
  placeholder = '●●●●●●●●',
  ...rest
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(value.trim().length !== 0);
  }, [value]);

  return (
    <InputContainer>
      {showPasswordIcon && (
        <>
          <IconWrapper>
            {showPassword ? (
              <Image
                src="/assets/icons/hide-password.svg"
                onClick={() => setShowPassword(false)}
                alt="icon of hide-password icon"
                width={16}
                height={16}
                quality={90}
              />
            ) : (
              <Image
                src="/assets/icons/show-password.svg"
                onClick={() => setShowPassword(true)}
                alt="icon of show-password icon"
                width={16}
                height={16}
                quality={90}
              />
            )}
          </IconWrapper>
        </>
      )}
      <InputLabel htmlFor="password">{label}</InputLabel>
      <InputWrapper placeholder={placeholder} isFocused={isFocused}>
        <Input {...rest} type={showPassword ? 'text' : 'password'} id={id} value={value} error={error} />
      </InputWrapper>
      <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
    </InputContainer>
  );
};

export default PasswordField;
