import { logAndThrowSentryError } from '../../utils/errors';
import { ApolloClient } from '../clients';
import {
  ADD_ITEM,
  UPDATE_ITEM,
  CREATE_CHECKOUT,
  GET_CHECKOUT,
  REMOVE_ITEM,
  UPDATE_CART_APPLY_DISCOUNT,
} from './queries';

export const getCheckout = (checkoutId: string): Promise<Shopify.Checkout> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_CHECKOUT,
    variables: {
      checkoutId,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCheckout', error);
    return data?.node as Shopify.Checkout;
  });

export const createCheckout = (): Promise<Shopify.CheckoutCreatePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CREATE_CHECKOUT,
    variables: {
      input: {},
    },
  }).then(({ data }) => data?.checkoutCreate);

export const addItem = (
  variantId: string,
  checkoutId: string,
  quantityNumber: number,
): Promise<Shopify.CheckoutLineItemsAddPayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: ADD_ITEM,
    variables: {
      lineItems: [{ variantId, quantity: quantityNumber }],
      checkoutId,
    },
  }).then(({ data }) => data?.checkoutLineItemsAdd);

export const addItems = (
  variants: Shopify.ProductVariant[],
  checkoutId: string,
  quantityNumber: number,
): Promise<Shopify.CheckoutLineItemsAddPayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: ADD_ITEM,
    variables: {
      lineItems: variants.map((variant) => ({
        variantId: variant.id,
        quantity: quantityNumber,
      })),
      checkoutId,
    },
  }).then(({ data }) => data?.checkoutLineItemsAdd);

export const removeItem = (
  lineItemIds: [string],
  checkoutId: string,
): Promise<Shopify.CheckoutLineItemsRemovePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: REMOVE_ITEM,
    variables: {
      lineItemIds,
      checkoutId,
    },
  }).then(({ data }) => data?.checkoutLineItemsRemove);

export const updateItem = (
  lineItems: Shopify.CheckoutLineItemUpdateInput[],
  checkoutId: string,
): Promise<Shopify.CheckoutLineItemsUpdatePayload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: UPDATE_ITEM,
    variables: {
      lineItems,
      checkoutId,
    },
  }).then(({ data }) => data?.checkoutLineItemsUpdate);

export const updateCartWithDiscount = (
  discountCode: string,
  checkoutId: string,
): Promise<Shopify.CheckoutDiscountCodeApplyV2Payload | null | undefined> =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: UPDATE_CART_APPLY_DISCOUNT,
    variables: {
      discountCode,
      checkoutId,
    },
  }).then(({ data }) => data?.checkoutDiscountCodeApplyV2);
