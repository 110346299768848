import { logAndThrowSentryError } from '../../utils/errors';
import { ApolloClient } from '../clients';
import {
  CREATE_ACCESS_TOKEN,
  CREATE_CUSTOMER,
  DELETE_ACCESS_TOKEN,
  GET_CUSTOMER,
  RENEW_ACCESS_TOKEN,
  CUSTOMER_RECOVER,
  CUSTOMER_RESET,
  CUSTOMER_ACTIVATE,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  UPDATE_DEFAULT_CUSTOMER_ADDRESS,
} from './queries';

export enum CustomerErrorCode {
  /** Input value is blank. */
  Blank = 'BLANK',
  /** Input value is invalid. */
  Invalid = 'INVALID',
  /** Input value is already taken. */
  Taken = 'TAKEN',
  /** Input value is too long. */
  TooLong = 'TOO_LONG',
  /** Input value is too short. */
  TooShort = 'TOO_SHORT',
  /** Unidentified customer. */
  UnidentifiedCustomer = 'UNIDENTIFIED_CUSTOMER',
  /** Customer is disabled. */
  CustomerDisabled = 'CUSTOMER_DISABLED',
  /** Input password starts or ends with whitespace. */
  PasswordStartsOrEndsWithWhitespace = 'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE',
  /** Input contains HTML tags. */
  ContainsHtmlTags = 'CONTAINS_HTML_TAGS',
  /** Input contains URL. */
  ContainsUrl = 'CONTAINS_URL',
  /** Invalid activation token. */
  TokenInvalid = 'TOKEN_INVALID',
  /** Customer already enabled. */
  AlreadyEnabled = 'ALREADY_ENABLED',
  /** Address does not exist. */
  NotFound = 'NOT_FOUND',
  /** Input email contains an invalid domain name. */
  BadDomain = 'BAD_DOMAIN',
  /** Multipass token is not valid. */
  InvalidMultipassRequest = 'INVALID_MULTIPASS_REQUEST',
}

export const getAccessToken = (email: string, password: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CREATE_ACCESS_TOKEN,
    variables: {
      input: {
        email,
        password,
      },
    },
  }).then(({ data }) => data?.customerAccessTokenCreate);

export const renewAccessToken = (customerAccessToken: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: RENEW_ACCESS_TOKEN,
    variables: {
      customerAccessToken,
    },
  }).then(({ data }) => data?.customerAccessTokenRenew);

export const deleteAccessToken = (customerAccessToken: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: DELETE_ACCESS_TOKEN,
    variables: {
      customerAccessToken,
    },
  }).then(({ data }) => data?.customerAccessTokenDelete);

export const getCustomer = (customerAccessToken: string, cursor?: string) =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_CUSTOMER,
    variables: {
      customerAccessToken,
      cursor,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCustomer', error);
    return data.customer;
  });

export const createCustomer = (
  email: string,
  password: string,
  acceptsMarketing?: boolean,
  firstName?: string,
  lastName?: string,
  phone?: string,
) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CREATE_CUSTOMER,
    variables: {
      input: {
        email,
        password,
        acceptsMarketing,
        firstName,
        lastName,
        phone,
      },
      undefined,
    },
  }).then(({ data }) => data?.customerCreate);

export const forgotPassword = (email: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CUSTOMER_RECOVER,
    variables: {
      email,
    },
  }).then(({ data }) => data?.customerRecover);

export const resetPassword = (resetUrl: string, password: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CUSTOMER_RESET,
    variables: {
      resetUrl,
      password,
      undefined,
    },
  }).then(({ data }) => data?.customerResetByUrl);

export const activateAccount = (activationUrl: string, password: string) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CUSTOMER_ACTIVATE,
    variables: {
      activationUrl,
      password,
      undefined,
    },
  }).then(({ data }) => data?.customerActivateByUrl);

export const getCustomerUserError = (errors?: Shopify.CustomerUserError[]): string | undefined => {
  if (!errors || !errors.length) return;

  switch (errors[0].code) {
    case CustomerErrorCode.AlreadyEnabled:
      return 'Your account is already enabled.';
    case CustomerErrorCode.BadDomain:
      return 'Your email contains an invalid domain name.';
    case CustomerErrorCode.Blank:
      return 'Your input is blank.';
    case CustomerErrorCode.ContainsHtmlTags:
      return 'Your input contains HTML tags.';
    case CustomerErrorCode.ContainsUrl:
      return 'Your input contains and URL.';
    case CustomerErrorCode.CustomerDisabled:
      return errors[0].message;
    case CustomerErrorCode.Invalid:
      return 'Your reset link is expired or invalid.';
    case CustomerErrorCode.InvalidMultipassRequest:
      return 'Multipass token is not valid.';
    case CustomerErrorCode.NotFound:
      return 'Address does not exist.';
    case CustomerErrorCode.PasswordStartsOrEndsWithWhitespace:
      return 'Your password cannot have any whitespace in it.';
    case CustomerErrorCode.Taken:
      return 'Your email has already been taken.';
    case CustomerErrorCode.TokenInvalid:
      return 'Invalid activation token.';
    case CustomerErrorCode.TooLong:
      return 'Your input is too long.';
    case CustomerErrorCode.TooShort:
      return 'Your input is too short.';
    case CustomerErrorCode.UnidentifiedCustomer:
      return 'Your email or password is incorrect.';
    default:
      return 'Sorry, an error has occurred.';
  }
};

type UpdateCustomerInput = {
  customerAccessToken: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  acceptsMarketing?: boolean;
};

export const updateCustomer = ({
  customerAccessToken,
  email,
  firstName,
  lastName,
  password,
  acceptsMarketing,
}: UpdateCustomerInput) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: UPDATE_CUSTOMER,
    variables: {
      customerAccessToken,
      customer: {
        email,
        firstName,
        lastName,
        password,
        acceptsMarketing,
      },
      undefined,
    },
  }).then(({ data }) => data?.customerUpdate);

type AddressInput = {
  customerAccessToken: string;
  address?: Shopify.MailingAddressInput;
  id?: string;
};

export const customerAddressCreate = ({ customerAccessToken, address }: AddressInput) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: CREATE_CUSTOMER_ADDRESS,
    variables: {
      customerAccessToken,
      address,
    },
  }).then(({ data }) => data?.customerAddressCreate);

export const customerAddressUpdate = ({ customerAccessToken, id, address }: AddressInput) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: UPDATE_CUSTOMER_ADDRESS,
    variables: {
      customerAccessToken,
      id,
      address,
    },
  }).then(({ data }) => data?.customerAddressUpdate);

export const customerAddressDelete = ({ customerAccessToken, id }: AddressInput) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: DELETE_CUSTOMER_ADDRESS,
    variables: {
      customerAccessToken,
      id,
    },
  }).then(({ data }) => data?.customerAddressDelete);

export const customerDefaultAddressUpdate = ({ id, customerAccessToken }: AddressInput) =>
  ApolloClient.mutate<Shopify.Mutation>({
    mutation: UPDATE_DEFAULT_CUSTOMER_ADDRESS,
    variables: {
      customerAccessToken,
      id,
      undefined,
    },
  }).then(({ data }) => data?.customerDefaultAddressUpdate);
