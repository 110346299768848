import React, { useState } from 'react';

const useWindowOffset = (): number => {
  const [windowOffset, setWindowOffset] = useState(0);

  function changeWindowScroll() {
    setWindowOffset(window.pageYOffset);
  }

  React.useEffect(() => {
    window.addEventListener('scroll', changeWindowScroll);

    return () => {
      window.removeEventListener('scroll', changeWindowScroll);
    };
  }, []);

  return windowOffset;
};

export default useWindowOffset;
