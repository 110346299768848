import React from 'react';
import styled from 'styled-components';
import { FlagBagType } from '../../context/flagging';

type FlaggingComponentProps = {
  flagBag: FlagBagType;
};

type ExperimentFlagComponentProps = {
  flagKey: string;
  flagValue: string;
};

const FlaggingWrapper = styled.div`
  display: none;
`;

const FlaggingComponent: React.FC<FlaggingComponentProps> = ({ flagBag }: FlaggingComponentProps) => {
  const getExperimentFlagComponentProps = (): ExperimentFlagComponentProps[] => {
    const experimentVariantMap: ExperimentFlagComponentProps[] = [];

    Object.keys(flagBag.experimentFlags).forEach((key) => {
      const experimentVariant = flagBag.experimentFlags[key];
      experimentVariantMap.push({ flagKey: key, flagValue: experimentVariant });
    });

    return experimentVariantMap;
  };
  return (
    <FlaggingWrapper data-testid="flagging-ids">
      {getExperimentFlagComponentProps().map((experimentFlag) => (
        <div
          key={experimentFlag.flagKey}
          data-flag-key={experimentFlag.flagKey}
          data-flag-value={experimentFlag.flagValue}
        />
      ))}
    </FlaggingWrapper>
  );
};

export default FlaggingComponent;
