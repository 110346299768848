import { State } from '.';
import { Action, ActionTypes } from './store.actions';

export const storeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SHOW_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          open: true,
        },
      };
    case ActionTypes.HIDE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          open: false,
        },
      };
    case ActionTypes.TOGGLE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          open: !state.cart.open,
        },
      };
    case ActionTypes.UPDATE_CHECKOUT:
      return {
        ...state,
        cart: {
          ...state.cart,
          checkout: action.payload,
        },
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case ActionTypes.UPDATE_CUSTOMER:
      return {
        ...state,
        user: {
          ...state.user,
          storefrontCustomer: action.payload,
        },
      };
    default:
      return state;
  }
};
