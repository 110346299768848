import { gql } from '@apollo/client';
import { PublicMetafields, CollectionMetafields } from '../utils/metafields';

const PRODUCT_METAFIELD_IDENTIFIERS = `[
  ${PublicMetafields.map((field) => `{ namespace: "${field.namespace}", key: "${field.key}"},`)}
]`;

const COLLECTION_METAFIELD_IDENTIFIERS = `[
  ${CollectionMetafields.map((field) => `{ namespace: "${field.namespace}", key: "${field.key}"},`)}
]`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductInfo on Product {
    id
    availableForSale
    title
    handle
    productType
    totalInventory
    tags
    vendor
    metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
      key
      value
      namespace
    }
    compareAtPriceRange {
      minVariantPrice {
        amount
      }
    }
    priceRange {
      minVariantPrice {
        amount
      }
    }
    variants(first: 10) {
      edges {
        node {
          id
          sku
          compareAtPriceV2 {
            amount
            currencyCode
          }
          image {
            altText
            transformedSrc(maxWidth: 500, preferredContentType: WEBP)
          }
          priceV2 {
            amount
            currencyCode
          }
          sku
        }
      }
    }
    images(first: $images) {
      edges {
        node {
          altText
          transformedSrc(maxWidth: 500, preferredContentType: WEBP)
        }
      }
    }
  }
`;

export const PRODUCT_FRAGMENT_REDUCED = gql`
  fragment ProductInfoReduced on Product {
    id
    availableForSale
    title
    handle
    productType
    totalInventory
    tags
    vendor
    metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
      key
      value
      namespace
    }
    compareAtPriceRange {
      minVariantPrice {
        amount
      }
    }
    priceRange {
      minVariantPrice {
        amount
      }
    }
    variants(first: 10) {
      edges {
        node {
          id
          title
          selectedOptions {
            name
            value
          }
          sku
          compareAtPriceV2 {
            amount
            currencyCode
          }
          priceV2 {
            amount
            currencyCode
          }
          sku
          product {
            id
            title
          }
        }
      }
    }
    images(first: $images) {
      edges {
        node {
          altText
          transformedSrc(maxWidth: 500, preferredContentType: WEBP)
        }
      }
    }
  }
`;

export const PRODUCT_DETAIL_FRAGMENT = gql`
  fragment ProductDetails on Product {
    id
    availableForSale
    title
    description
    descriptionHtml
    productType
    totalInventory
    tags
    handle
    vendor
    isGiftCard
    compareAtPriceRange {
      minVariantPrice {
        amount
      }
    }
    priceRange {
      minVariantPrice {
        amount
      }
    }
    metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
      key
      value
      namespace
    }
    variants(first: 10) {
      edges {
        node {
          id
          sku
          title
          selectedOptions {
            name
            value
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
          image {
            altText
            transformedSrc(maxWidth: $maxWidth, preferredContentType: WEBP)
          }
          priceV2 {
            amount
            currencyCode
          }
          product {
            id
            title
          }
        }
      }
    }
    images(first: 40) {
      edges {
        node {
          altText
          transformedSrc(maxWidth: $maxWidth, preferredContentType: WEBP)
        }
      }
    }
    collections(first: 25) {
      edges {
        node {
          title
          handle
          metafields(identifiers: ${COLLECTION_METAFIELD_IDENTIFIERS}) {
            key
            value
            namespace
          }
        }
      }
    }
  }
`;

export const POLICY_FRAGMENT = gql`
  fragment PolicyDetails on ShopPolicy {
    body
    id
    title
    url
  }
`;

export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutDetails on Checkout {
    id
    webUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    completedAt
    lineItems(first: 25) {
      edges {
        node {
          discountAllocations {
            allocatedAmount {
              amount
            }
          }
          id
          quantity
          title
          variant {
            id
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            image {
              altText
              transformedSrc(maxWidth: 250, preferredContentType: WEBP)
            }
            title
            product {
              id
              title
              handle
              productType
              tags
              metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
                key
                value
                namespace
              }
            }
          }
        }
      }
    }
  }
`;

const MAILING_ADDRESS_FRAGMENT = gql`
  fragment MailingAddress on MailingAddress {
    id
    formatted
    address1
    address2
    city
    company
    country
    countryCodeV2
    firstName
    lastName
    phone
    province
    provinceCode
    zip
  }
`;

export const CUSTOMER_FRAGMENT = gql`
  ${CHECKOUT_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
  fragment CustomerInfo on Customer {
    id
    displayName
    defaultAddress {
      ...MailingAddress
    }
    email
    firstName
    lastName
    phone
    acceptsMarketing
    lastIncompleteCheckout {
      ...CheckoutDetails
    }
    addresses(first: 25) {
      edges {
        node {
          ...MailingAddress
        }
      }
    }
    orders(first: 25, reverse: true, after: $cursor) {
      edges {
        node {
          id
          name
          cancelReason
          orderNumber
          processedAt
          financialStatus
          fulfillmentStatus
          subtotalPriceV2 {
            amount
          }
          totalShippingPriceV2 {
            amount
          }
          totalPriceV2 {
            amount
          }
          totalTaxV2 {
            amount
          }
          totalRefundedV2 {
            amount
          }
          shippingAddress {
            ...MailingAddress
          }
          lineItems(first: 25) {
            edges {
              node {
                title
                quantity
                currentQuantity
                originalTotalPrice {
                  amount
                }
                discountedTotalPrice {
                  amount
                }
                variant {
                  title
                  sku
                  product {
                    handle
                    productType
                    tags
                    metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
                      key
                      value
                      namespace
                    }
                    isGiftCard
                  }
                  image {
                    altText
                    transformedSrc(maxWidth: 200, preferredContentType: WEBP)
                  }
                }
              }
            }
          }
          successfulFulfillments(first: 25) {
            fulfillmentLineItems(first: 25) {
              edges {
                node {
                  lineItem {
                    title
                  }
                }
              }
            }
            trackingCompany
            trackingInfo(first: 25) {
              url
              number
            }
          }
        }
        cursor
      }
    }
  }
`;

export const ORDER_FRAGMENT = gql`
  ${MAILING_ADDRESS_FRAGMENT}
  fragment OrderInfo on Order {
    id
    name
    cancelReason
    processedAt
    shippingAddress {
      ...MailingAddress
    }
    lineItems(first: 25) {
      edges {
        node {
          title
          product {
            tags
            metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
              key
              value
              namespace
            }
          }
        }
      }
    }
    fulfillments(first: 25) {
      fulfillmentLineItems(first: 25) {
        edges {
          node {
            lineItem {
              title
              quantity
              currentQuantity
              discountedUnitPriceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
              image {
                altText
                transformedSrc(maxWidth: 200, preferredContentType: WEBP)
              }
              variant {
                sku
                product {
                  handle
                  productType
                }
              }
            }
          }
        }
      }
      trackingInfo(first: 25) {
        url
        number
        company
      }
    }
  }
`;

export const COLLECTION_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  fragment CollectionInfo on Collection {
    title
    handle
    metafield(namespace: $namespace, key: $key) {
      key
      value
      namespace
    }
    products(first: 25) {
      edges {
        node {
          ...ProductInfo
        }
        cursor
      }
    }
  }
`;
