import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { Link as StyledLink } from '@makeship/core';
import posthog from 'posthog-js';
import { Caption, CaptionStyles, P2Styles, S1 } from '../Typography';
import Accordion, { AccordionSectionType } from '../Accordion';
import SubscribeModal from '../Modal/SubscribeModal';
import Social, { defaultSocialLinks } from '../Social';

import config from '../../../config.json';

const { routes } = config;

const FooterContainer = styled.div`
  background: linear-gradient(180deg, #fff 0%, #dbd6e9 100%);
  width: 100%;
`;

const FooterWrapper = styled.div`
  max-width: ${({ theme }) => theme.containerWidth}px;
  margin: 0 auto;
  padding: 40px 64px 16px 64px;
  margin: 0 auto 0 auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 40px 16px 16px 16px;
  }
`;

const FooterRow = styled.div<{ hideMobile?: boolean; center?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ hideMobile }) => hideMobile && `display: none;`}
    ${({ center }) => center && `justify-content: center;`}
  }
  ${({ center }) =>
    center &&
    `
      border-top: 1px solid var(--brand-colors-2023-eclipse-black-partial-33, rgba(44, 0, 115, 0.33));
      padding-top: 16px;
    `}
`;

const FooterSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

const FooterSection = styled(FooterRow)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

const FooterList = styled.div<{ hideMobile?: boolean }>`
  margin: 48px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ hideMobile }) => hideMobile && `display: none;`};
    margin: 24px 0;
  }
`;

const FooterSplit = styled.div<{ first?: boolean }>`
  margin-right: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ first }) => !first && `margin-top: -16px;`}
  }
`;

const FooterSectionTitle = styled(S1)`
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.footerBorder};
`;

const FooterLink = styled(StyledLink.Primary)`
  ${P2Styles};
  color: ${({ theme }) => theme.colors.neutral7} !important;
  display: block;
  margin: 16px 0;
  cursor: pointer;
  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

const FooterPolicy = styled(StyledLink.Primary)`
  ${CaptionStyles};
  color: ${({ theme }) => theme.colors.neutral7} !important;
  display: block;
  margin-right: 32px;
  cursor: pointer;
  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${P2Styles}
    margin-bottom: 16px;
  }
`;

const HideDesktop = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: inline-block;
  }
`;

const SocialMobile = styled(HideDesktop)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 42px 0 24px 0;
  }
`;

const CopyrightCaption = styled(Caption)``;

const FooterBoldLink = styled(FooterLink)``;

const getPolicyLinks = () => {
  const policyLinks = (
    <>
      <Link href={routes.privacyPolicy} passHref>
        <FooterPolicy>Privacy Policy</FooterPolicy>
      </Link>
      <Link href={routes.termsOfService} passHref>
        <FooterPolicy>Terms of Service</FooterPolicy>
      </Link>
      <Link href={routes.shippingPolicy} passHref>
        <FooterPolicy>Shipping Policy</FooterPolicy>
      </Link>
      <a href={routes.refundPolicy} target="_blank" rel="noreferrer noopener">
        <FooterPolicy>Refund Policy</FooterPolicy>
      </a>
      <Link href={routes.cookiesPolicy} passHref>
        <FooterPolicy>Cookies Policy</FooterPolicy>
      </Link>
    </>
  );

  return policyLinks;
};
const getFooterData = () => {
  const footerData: AccordionSectionType[] = [
    {
      title: 'Who is Makeship?',
      body: [
        {
          body: (
            <FooterSectionRow>
              <FooterSplit first>
                <Link
                  href={routes.aboutUs}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', { link_title: 'About Us', link_href: routes.aboutUs })
                  }
                >
                  <FooterLink>About Us</FooterLink>
                </Link>
                <Link
                  href={routes.launchCampaign}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', {
                      link_title: 'Launch Campaign',
                      link_href: routes.launchCampaign,
                    })
                  }
                >
                  <FooterLink data-testid="launch-campaign-footer-cta">Launch Campaign</FooterLink>
                </Link>
                <Link
                  href={routes.howItWorks}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', {
                      link_title: 'How it Works',
                      link_href: routes.howItWorks,
                    })
                  }
                >
                  <FooterLink>How it Works</FooterLink>
                </Link>
              </FooterSplit>
              <FooterSplit>
                <Link
                  href={routes.sustainability}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', {
                      link_title: 'Sustainability',
                      link_href: routes.sustainability,
                    })
                  }
                >
                  <FooterLink>Sustainability</FooterLink>
                </Link>
                <Link
                  href={routes.careers}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', {
                      link_title: 'Careers',
                      link_href: routes.careers,
                    })
                  }
                >
                  <FooterLink>Careers</FooterLink>
                </Link>
                <Link
                  href={routes.community}
                  passHref
                  onClick={() =>
                    posthog.capture('footer_link_click', {
                      link_title: 'Community',
                      link_href: routes.community,
                    })
                  }
                >
                  <FooterLink>Community</FooterLink>
                </Link>
              </FooterSplit>
            </FooterSectionRow>
          ),
        },
      ],
    },
    {
      title: 'Support',
      body: [
        {
          body: (
            <>
              <Link
                href={routes.helpCenter}
                passHref
                onClick={() =>
                  posthog.capture('footer_link_click', {
                    link_title: 'Help Center',
                    link_href: routes.helpCenter,
                  })
                }
              >
                <FooterLink>Help Center</FooterLink>
              </Link>
              <Link
                href={routes.shippingDestinations}
                passHref
                onClick={() =>
                  posthog.capture('footer_link_click', {
                    link_title: 'Shipping Destinations',
                    link_href: routes.shippingDestinations,
                  })
                }
              >
                <FooterLink>Shipping Destinations</FooterLink>
              </Link>
              <FooterLink
                target="_blank"
                href={routes.makeshipAftership}
                rel="noopener noreferrer"
                onClick={() =>
                  posthog.capture('footer_link_click', {
                    link_title: 'Track Your Order',
                    link_href: routes.makeshipAftership,
                  })
                }
              >
                Track Your Order
              </FooterLink>
              <HideDesktop>{getPolicyLinks()}</HideDesktop>
            </>
          ),
        },
      ],
    },
  ];

  return footerData;
};

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleSignInKeyPress(event: React.KeyboardEvent<HTMLAnchorElement>) {
    if (event.code === 'Space' || event.code === 'Enter') {
      // Prevent spacebar from scrolling page down when focus is on this element
      event.preventDefault();
      setIsModalOpen(true);
    }
  }

  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <FooterRow>
            <Image src="/assets/footer-logo.svg" width={170} height={24} quality={90} alt="makeship logo" />
            <FooterRow hideMobile>
              <Social links={defaultSocialLinks} />
            </FooterRow>
          </FooterRow>
          <FooterSection>
            <FooterList>
              <FooterBoldLink
                onClick={() => {
                  posthog.capture('footer_link_click', {
                    link_title: 'Sign up for Email',
                  });
                  setIsModalOpen(true);
                }}
                onKeyPress={(e) => handleSignInKeyPress(e)}
                tabIndex={0}
              >
                Sign up for Email
              </FooterBoldLink>
              <Link
                href={routes.login}
                passHref
                onClick={() =>
                  posthog.capture('footer_link_click', {
                    link_title: 'Sign In/Up',
                    link_href: routes.login,
                  })
                }
              >
                <FooterBoldLink>Sign In/Up</FooterBoldLink>
              </Link>
            </FooterList>
            {getFooterData().map((section) => (
              <FooterList key={section.title} hideMobile>
                <FooterSectionTitle>{section.title}</FooterSectionTitle>
                {section.body.map((body, index) => (
                  <div key={body.title || `body section ${index}`}>{body.body}</div>
                ))}
              </FooterList>
            ))}
            <FooterList hideMobile />
            <HideDesktop>
              <Accordion data={getFooterData()} accordionLocation="footer" />
            </HideDesktop>
          </FooterSection>
          <SocialMobile>
            <Social links={defaultSocialLinks} socialLocation="footer" />
          </SocialMobile>
          <FooterRow center>
            <FooterRow hideMobile>{getPolicyLinks()}</FooterRow>
            <CopyrightCaption>&copy; {new Date().getFullYear()} Makeship Ltd.</CopyrightCaption>
          </FooterRow>
        </FooterWrapper>
      </FooterContainer>
      <SubscribeModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default Footer;
