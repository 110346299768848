import React, { createContext, useState } from 'react';
import posthog from 'posthog-js';
import { fetchUserIdentifier } from '../utils/localstorage';

interface SpamFlaggingProps {
  children?: React.ReactNode;
}

const SpamFlaggingContext = createContext({
  counter: 0,
  isSpam: false,
  incrementSpamCounter: () => {},
});
const SPAM_THRESHOLD = 20;

export const SpamFlaggingProvider = ({ children }: SpamFlaggingProps) => {
  const [spamCounter, setSpamCounter] = useState<number>(0);
  const [isSpam, setIsSpam] = useState<boolean>(false);

  const incrementSpamCounter = () => {
    if (isSpam) return;

    const newCounter = spamCounter + 1;
    if (newCounter >= SPAM_THRESHOLD) {
      setIsSpam(true);
      const anonID = fetchUserIdentifier();
      posthog.identify(anonID, { isSpam: true });
    }
    return setSpamCounter(newCounter);
  };

  return (
    <SpamFlaggingContext.Provider value={{ counter: spamCounter, isSpam, incrementSpamCounter }}>
      {children}
    </SpamFlaggingContext.Provider>
  );
};

export default SpamFlaggingContext;
