var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bbd0c5c915d769916f0c31001b92f1657e1eea71"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || '';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  environment: ENVIRONMENT,
  enabled: ENVIRONMENT !== 'development',
  ignoreErrors: ['Failed to fetch', 'cancelled', 'NetworkError'],
  // Ignore Recaptcha false positive error - https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
});
