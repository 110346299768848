type PublicMetafield = {
  namespace: string;
  key: string;
};

// Add new metafields here
export const PublicMetafields: PublicMetafield[] = [
  { namespace: 'product', key: 'promotionalImage' },
  { namespace: 'product', key: 'promotionalCaption' },
  { namespace: 'product', key: 'campaignID' },
  { namespace: 'product', key: 'charity' },
  { namespace: 'product', key: 'promotion' },
  { namespace: 'product', key: 'gift' },
  { namespace: 'image', key: 'brand' },
  { namespace: 'size', key: 'height' },
  { namespace: 'size', key: 'width' },
  { namespace: 'size', key: 'depth' },
  { namespace: 'color', key: 'color' },
  { namespace: 'stock', key: 'moq' },
  { namespace: 'stock', key: 'totalsold' },
  { namespace: 'stock', key: 'inventory' },
  { namespace: 'date', key: 'launch' },
  { namespace: 'date', key: 'delivery' },
  { namespace: 'date', key: 'enddate' },
  { namespace: 'name', key: 'creator' },
  { namespace: 'date', key: 'petitionLaunchDate' },
  { namespace: 'date', key: 'petitionEndDate' },
  { namespace: 'date', key: 'paymentDue' },
  { namespace: 'product', key: 'petitionWhy' },
  { namespace: 'product', key: 'petitionStory' },
  { namespace: 'product', key: 'completeCollectionDiscountPercent' },
  { namespace: 'product', key: 'prideCharityName' },
  { namespace: 'product', key: 'pridePercentDonated' },
  { namespace: 'product', key: 'pinAddOn' },
];

// Collection metafields here
export const CollectionMetafields: PublicMetafield[] = [
  { namespace: 'global', key: 'isCreatorCollection' },
  { namespace: 'global', key: 'isMakeshiftCollection' },
];
