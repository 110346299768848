import React from 'react';
import styled from 'styled-components';
import { Caption, OverlineStyle2, P2Styles } from '../Typography';

export type SelectInputProps = {
  id: string;
  label: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
};

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LabelsWrapper = styled.div`
  display: flex;
`;

const InputLabel = styled.label`
  ${P2Styles}
  display: inline-block;
  margin-bottom: 6px;
`;

const RequiredLabel = styled(OverlineStyle2)`
  display: flex;
  align-items: center;
  margin-left: auto;
  text-transform: capitalize;
`;

const ErrorMessage = styled(Caption)<{ hide: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

const SelectField: React.FC<SelectInputProps> = ({
  children,
  label,
  id,
  error,
  errorMessage,
  required,
}: SelectInputProps) => (
  <InputContainer>
    <InputWrapper>
      <LabelsWrapper>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {required && <RequiredLabel>Required</RequiredLabel>}
      </LabelsWrapper>
      {children}
    </InputWrapper>
    <ErrorMessage hide={!error}>{errorMessage}</ErrorMessage>
  </InputContainer>
);

export default SelectField;
