import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const cleanHostname = (hostname: string) => hostname.replace('www.', '');

export const anonIDKey = 'anonID';

export const fetchUserIdentifier = () => {
  if (!Cookies.get(anonIDKey)) {
    if (!localStorage.getItem(anonIDKey)) {
      localStorage.setItem(anonIDKey, uuidv4());
    }
    const anonID = localStorage.getItem(anonIDKey) as string;

    Cookies.set(anonIDKey, anonID, {
      expires: 365,
      domain: `.${cleanHostname(window.location.hostname)}`,
      sameSite: 'lax',
    });
    return anonID;
  }

  return Cookies.get(anonIDKey);
};
