export const isGITDProduct = (tags: string[]): boolean => tags.some((tag) => tag.toLowerCase().includes('gitd'));

export const getGITDColourTag = (tags: string[]): string | undefined =>
  tags.find((tag) => tag.toLowerCase().includes('gitd'));

export const reorderBFCMProductImagesToPlaceGIFsFirst = (
  images: Shopify.ImageEdge[],
  tags: string[],
): Shopify.ImageEdge[] => {
  if (!isGITDProduct(tags)) {
    return images;
  }

  const gifImages = images.filter((image) => image.node.transformedSrc.toLowerCase().includes('_live'));
  const nonGifImages = images.filter((image) => !image.node.transformedSrc.toLowerCase().includes('_live'));

  return [...gifImages, ...nonGifImages];
};

export const isHolidayHideawayProduct = (productID: string): boolean =>
  productID.includes('8287224103068') || productID.includes('7579692105867');

// Should be after BFCM after Dec 3, 12PM EST
export const isAfterBFCMWeekend = (date: Date): boolean =>
  new Date(date).getTime() >= new Date('2024-12-03T12:00:00-05:00').getTime();
