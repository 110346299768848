import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { utils, Button } from '@makeship/core';
import { H4Style2, P2 } from '../Typography';

export enum AlertType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
}

type AlertProps = {
  title: string;
  body: string;
  type: AlertType;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButton?: string;
  cancelButton?: string;
};

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  overflow: none;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 525px;
  padding: 16px;
  margin: 0 16px;
  background-color: ${({ theme }) => theme.colors.neutral1};
  border-radius: 3px;
`;

const AlertTitle = styled(H4Style2)<{ type: AlertType }>`
  color: ${({ theme, type }) => theme.colors[type]};
`;

const AlertBody = styled(P2)`
  margin: 16px 0;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CancelButton = styled(Button.Secondary)<{ padding: boolean }>`
  ${({ padding }) => padding && `margin-right: 8px;`}
`;

const ConfirmButton = styled(Button.Primary)<{ padding: boolean }>`
  ${({ padding }) => padding && `margin-left: 8px;`}
`;

const Alert: React.FC<AlertProps> = ({
  title,
  body,
  type,
  confirmButton,
  cancelButton,
  onConfirm,
  onCancel,
}: AlertProps) => (
  <Overlay>
    <Modal>
      <AlertTitle type={type}>{title}</AlertTitle>
      <AlertBody>{body}</AlertBody>
      <ButtonRow>
        {cancelButton && (
          <CancelButton size={Button.Size.small} padding={!!confirmButton} onClick={onCancel}>
            {cancelButton}
          </CancelButton>
        )}
        {confirmButton && (
          <ConfirmButton size={Button.Size.small} padding={!!cancelButton} onClick={onConfirm}>
            {confirmButton}
          </ConfirmButton>
        )}
      </ButtonRow>
    </Modal>
  </Overlay>
);

const AlertContext = React.createContext<(options: AlertProps) => Promise<boolean>>(Promise.reject);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAlert = () => useContext(AlertContext);

type AlertServiceProviderProps = {
  children: React.ReactNode;
};

const AlertServiceProvider: React.FC<AlertServiceProviderProps> = ({ children }: AlertServiceProviderProps) => {
  const [option, setOptions] = useState<AlertProps | null>(null);

  const ref = React.useRef<{
    resolve: (res: boolean) => void;
  }>();

  const openAlert = (options: AlertProps) => {
    setOptions(options);
    return new Promise<boolean>((resolve) => {
      ref.current = { resolve };
    });
  };

  const handleCancel = () => {
    if (ref.current) {
      ref.current.resolve(false);
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (ref.current) {
      ref.current.resolve(true);
    }
    setOptions(null);
  };

  return (
    <>
      <AlertContext.Provider value={openAlert}>{children}</AlertContext.Provider>
      {option && <Alert {...option} onConfirm={handleConfirm} onCancel={handleCancel} />}
    </>
  );
};

export default AlertServiceProvider;
