import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { Button } from '@makeship/core';

import posthog from 'posthog-js';
import Cookies from 'js-cookie';
import Modal from '..';
import { Caption, H8 } from '../../Typography';
import { modalTheme } from '../../../styles/themes';
import { ToggleSwitch } from '../../Input';
import { CookieConsent } from '../../../types/cookies';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ModalContent = styled.div`
  width: 100%;
  padding: 32px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primaryDarker};
  align-items: center;
  position: relative;
`;

const Heading = styled(H8)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const StyledCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 32px;
  top: 26px;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  text-align: left;
  margin: 16px 0;
`;

const CookiePreferencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`;

const CookieOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button.Secondary)`
  width: 250px;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.neutral1};
  text-decoration: underline;
  cursor: pointer;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-top: 18px;
`;

const CookieModalTheme = {
  ...modalTheme,
  content: {
    ...modalTheme.content,
    borderRadius: '0px 30px 30px 0px',
    width: '626px',
    border: 'transparent',
    marginTop: '20px',
  },
};

type CookieModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

interface CookieOptionProps {
  title: string;
  description: string;
  checked: boolean;
  onToggle: () => void;
}

const CookieOption: React.FC<CookieOptionProps> = ({ title, description, checked, onToggle }: CookieOptionProps) => (
  <CookieOptionWrapper>
    <HeadingWrapper>
      <Heading>{title}</Heading>
      <ToggleSwitch checked={checked} onToggle={onToggle} />
    </HeadingWrapper>
    <StyledCaption>{description}</StyledCaption>
  </CookieOptionWrapper>
);

const CookieModal: React.FC<CookieModalProps> = ({ isOpen, closeModal }: CookieModalProps) => {
  const [cookiePreferences, setCookiePreferences] = React.useState({
    [CookieConsent.Functional]: true,
    [CookieConsent.Statistical]: true,
    [CookieConsent.ThirdParty]: true,
  });

  const handleToggle = (type: CookieConsent) => {
    setCookiePreferences((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const cookieOptions = [
    {
      id: CookieConsent.Functional,
      title: 'Functional',
      description:
        'Help perform certain functionalities like the collection page scroll, sharing content on social media platforms, collecting feedback, and other third-party features.',
    },
    {
      id: CookieConsent.Statistical,
      title: 'Statistical',
      description:
        'Used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
    },
    {
      id: CookieConsent.ThirdParty,
      title: 'Third-Party',
      description:
        'Used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.',
    },
  ];

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} theme={CookieModalTheme}>
      <ModalContent>
        <CancelIcon onClick={closeModal}>
          <Image src="/assets/icons/cancel-icon-white.svg" width="16" height="16" quality={90} />
        </CancelIcon>
        <ModalHeader>
          <Heading>🍪 Cookie Consent Preferences</Heading>
        </ModalHeader>
        <CookiePreferencesWrapper>
          <CookieOptionWrapper>
            <HeadingWrapper>
              <Heading>Essential Cookies</Heading>
              <Heading>(Mandatory)</Heading>
            </HeadingWrapper>
            <StyledCaption>
              Required to enable the basic features of this site, such as navigating to checkout, providing secure
              log-in, or adjusting your consent preferences. These cookies do not store any personally identifiable
              data.
            </StyledCaption>
          </CookieOptionWrapper>

          {cookieOptions.map((option) => (
            <CookieOption
              key={option.id}
              title={option.title}
              description={option.description}
              checked={cookiePreferences[option.id]}
              onToggle={() => handleToggle(option.id)}
            />
          ))}

          <CTAWrapper>
            <StyledButton
              onClick={() => {
                Cookies.set('cookie_actioned', 'true', { expires: 365 });
                for (const [key, value] of Object.entries(cookiePreferences)) {
                  Cookies.set(key, value.toString(), {
                    expires: 365,
                  });
                }
                if (cookiePreferences[CookieConsent.Statistical]) {
                  posthog.set_config({ persistence: 'localStorage+cookie' });
                }
                if (cookiePreferences[CookieConsent.ThirdParty]) {
                  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
                  dataLayer.push({ event: 'cookie_consent' });
                  Cookies.set('_kla_off', 'false', { expires: 365 });
                }
                posthog.capture('cookie_consent_accepted', {
                  location: 'cookie_modal',
                  cookie_consent_choices: cookiePreferences,
                });
                closeModal();
              }}
            >
              {cookiePreferences[CookieConsent.Functional] &&
              cookiePreferences[CookieConsent.Statistical] &&
              cookiePreferences[CookieConsent.ThirdParty]
                ? 'Accept All'
                : 'Save Preferences'}
            </StyledButton>
            <StyledA
              onClick={() => {
                Cookies.set('cookie_actioned', 'true', { expires: 365 });
                for (const [key] of Object.entries(cookiePreferences)) {
                  Cookies.set(key, 'false', {
                    expires: 365,
                  });
                }
                posthog.capture('cookie_consent_rejected', { location: 'cookie_modal' });
                closeModal();
              }}
            >
              Reject Non-Mandatory Cookies
            </StyledA>
          </CTAWrapper>
        </CookiePreferencesWrapper>
      </ModalContent>
    </Modal>
  );
};

export default CookieModal;
