import { AxiosResponse } from 'axios';
import { StorefrontClient } from '../../clients';

export const getCustomer = async (customerAccessToken: string): Promise<AxiosResponse<Customer>> =>
  StorefrontClient.get<Customer>('/customers', { params: { customerAccessToken } }).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const createCustomer = async (createCustomerRequest: CreateCustomerRequest): Promise<AxiosResponse<Customer>> =>
  StorefrontClient.post<Customer>('/customers', createCustomerRequest).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const updateCustomer = async (updateCustomerRequest: UpdateCustomerRequest): Promise<AxiosResponse<Customer>> =>
  StorefrontClient.put<Customer>('/customers', updateCustomerRequest).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const addTagsToCustomer = async (
  addTagsToCustomerRequest: AddTagsToCustomerByEmailRequest,
): Promise<AxiosResponse<Customer>> =>
  StorefrontClient.put<Customer>('/customers/tags', addTagsToCustomerRequest).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );
