import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { S1, S2 } from '../Typography';

const CartQuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartQuantityButton = styled.button<{ disabled: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ theme, disabled }) =>
    disabled ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark) : theme.colors.primary};
  color: ${({ theme }) => theme.colors.neutral1};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  ${S2} {
    color: ${({ theme }) => theme.colors.neutral1};
  }
  &:active,
  :focus,
  :focus-visible {
    outline: none;
  }
`;

const QuantityText = styled(S1)`
  margin: 0 16px;
`;

type StepperProps = {
  value: number;
  increase: () => void;
  decrease: () => void;
  isLimited?: boolean;
  isLimitedQtyOutOfStock?: boolean;
};

const Stepper: React.FC<StepperProps> = ({
  value,
  increase,
  decrease,
  isLimited,
  isLimitedQtyOutOfStock,
}: StepperProps) => (
  <CartQuantityWrapper>
    <CartQuantityButton onClick={decrease} disabled={false}>
      <S2>-</S2>
    </CartQuantityButton>
    <QuantityText>{value}</QuantityText>
    {isLimited && isLimitedQtyOutOfStock ? (
      <CartQuantityButton onClick={increase} disabled>
        <S2>+</S2>
      </CartQuantityButton>
    ) : (
      <CartQuantityButton onClick={increase} disabled={false}>
        <S2>+</S2>
      </CartQuantityButton>
    )}
  </CartQuantityWrapper>
);

export default Stepper;
