import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { ErrorType, VariantSelectionError } from '../types/errors';

export const logAndThrowSentryError = (queryLocation: string, error: ApolloError) => {
  Sentry.captureMessage(`Error during apollo query: ${queryLocation}.\n
    Error:${error.name}, ErrorMsg: ${error.message}`);
  throw new Error(error.message);
};

export const doesErrorsContainProductID = (errors: VariantSelectionError[], productID: string | undefined) =>
  productID && errors.some((error) => error?.productID === productID);

export const findByErrorType = (
  errors: VariantSelectionError[],
  productID: string | undefined,
  errorType: ErrorType,
) => {
  if (!productID) return false;
  return errors.some((error) => error?.productID === productID && error?.error === errorType);
};

export const getVariantIDErrorFromProductID = (errors: VariantSelectionError[], productID: string | undefined) => {
  if (!productID || errors.length === 0) return undefined;
  return errors.find((error) => error?.productID === productID);
};

export const addVariantSelectionErrorIfNotAlreadyPresent = (
  errors: VariantSelectionError[],
  productID: string,
  error: ErrorType,
): VariantSelectionError[] => {
  if (doesErrorsContainProductID(errors, productID)) {
    return errors;
  }
  return [...errors, { productID, error }];
};
