import { GetServerSidePropsContext } from 'next';
import { v4 as uuidv4 } from 'uuid';
import { parse, serialize } from 'cookie';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import { anonIDKey } from './localstorage';
import { CookieConsent } from '../types/cookies';

export const accessTokenKey = 'accessToken';
export const usernameKey = 'username';
export const checkoutIdKey = 'checkoutId';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const handleAnonID = async (ctx: GetServerSidePropsContext) => {
  const cookies = parse(ctx.req.headers.cookie || '');
  let { anonID } = cookies;

  if (!anonID) {
    anonID = uuidv4() as string;
    const cookie = serialize(anonIDKey, anonID, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
      sameSite: 'lax',
    });

    ctx.res.setHeader('Set-Cookie', cookie);
  }

  return anonID;
};

// Used to check if request continent is from EU
// If from EU, assume it needs GDPR compliance
export const isReqFromEU = (ctx: GetServerSidePropsContext) =>
  (ctx.req.headers['x-vercel-ip-continent'] || '') === 'EU';

// Accepts all cookies and turns on all 3rd party tracking
export const acceptAllCookies = () => {
  Cookies.set(CookieConsent.ThirdParty, 'true', { expires: 365 });
  Cookies.set(CookieConsent.Statistical, 'true', { expires: 365 });
  Cookies.set(CookieConsent.Functional, 'true', { expires: 365 });

  // Turn on all 3rd party cookies
  posthog.set_config({ persistence: 'localStorage+cookie' });
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  dataLayer.push({ event: 'cookie_consent' });
  Cookies.set('_kla_off', 'false', { expires: 365 });
  if (typeof window !== 'undefined' && window.TriplePixel) {
    window.TriplePixel('gdpr', false);
  }
};
