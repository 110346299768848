import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STOREFRONT_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

client.interceptors.request.use((req: AxiosRequestConfig) => {
  if (req.data) {
    req.data = qs.stringify(req.data);
  }
  return req;
});

export default client;
