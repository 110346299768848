import axios from 'axios';

export const subscribe = async (email: string, captcha: string, tags: string | undefined) =>
  axios
    .post('/api/subscribe', { email, captcha, tags })
    .then((data) => Promise.resolve(data))
    .catch((err) => Promise.reject(err));

export const checkout = async (accessToken: string, webUrl: string) =>
  axios.post('/api/checkout', { accessToken, webUrl });
